import React, {useState, useRef, useEffect} from 'react'
import Card from '../property-card'
import { graphql, useStaticQuery } from 'gatsby';
import OwlCarousel from 'react-owl-carousel3';
import { getOperations } from '../../helpers/helper.properties';
import {redirectStatic} from "../../helpers/helper.rendering"

//Redux
import { connect } from 'react-redux'

//Dispatch
import {getFeaturedPropertiesAction} from '../../redux/propertiesDucks'

const options = {
    // loop:true,
    smartSpeed:1000,
    stagePadding:10,
    // center:true,
    margin:10,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,

        },
        1200: {
            items: 3,

        }
    },
}

const Properties = ({properties,api_key,dispatch}) => {

    const [propArr, setPropArr] = useState([]);
    // const [filter, setFilter] = useState(4)
    // const [buttons, setButton] = useState([
    //     {
    //         name:"TODOS",
    //         data:'Todos',
    //         value:4
    //     },
    //     {
    //         name:"VIVIENDAS",
    //         data:'viviendas',
    //         value:2
    //     },
    //     {
    //         name:"LOCALES COMERCIALES",
    //         data:'Local',
    //         value:0
    //     },
    //     {
    //         name:"OFICINAS",
    //         data:'Oficina',
    //         value:1
    //     },
    //     {
    //         name:"TERRENOS",
    //         data:'Terreno',
    //         value:3
    //     }
    // ]);
    const [filter, setFilter] = useState('Venta')
    const [buttons, setButtos] = useState([
        {
            name:"VENTAS",
            data:'Venta',
            value:0
        },
        {
            name:"ALQUILER",
            data:'Alquiler',
            value:1
        },
        {
            name:"ALQUILER TEMP.",
            data:'Alquiler temporario',
            value:2
        }

    ])
    const slider = useRef()
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                sections {
                    home {
                        properties{
                            title
                        }
                    }
                }
            } 
        }`)

    useEffect(() => {
        if(api_key){
            dispatch(getFeaturedPropertiesAction())
        }
    }, [api_key])

    // useEffect(() => {
    //     if(properties) {
    //         switch (filter) {
    //             case 0:
    //                 arrFilteredByType(properties?.data?.objects, ['Local'])
    //                 setPropArr(arrFilteredByType(properties?.data?.objects, ['Local']))
    //                 break;

    //             case 1:
    //                 arrFilteredByType(properties?.data?.objects, ['Oficina'])
    //                 setPropArr(arrFilteredByType(properties?.data?.objects, ['Oficina']))
    //                 break;

    //             case 2:
    //                 arrFilteredByType(arrFilteredByOp(properties?.data?.objects), ['Casa', 'Departamento', 'PH'])
    //                 setPropArr(arrFilteredByType(arrFilteredByOp(properties?.data?.objects), ['Casa', 'Departamento', 'PH']))
    //                 break;

    //             case 3:
    //                 arrFilteredByType(properties?.data?.objects, ['Terreno'])
    //                 setPropArr(arrFilteredByType(properties?.data?.objects, ['Terreno']))
    //                 break;
            
    //             default:
    //                 setPropArr(properties?.data?.objects)
    //                 break;
    //         }
    //     }
    // }, [properties, filter])

    // const arrFilteredByType = (props, types = []) => {
    //     if (props) {
    //         if(types.length > 0) {
    //             let arrAux = [];
    //             for (let i = 0; i < types.length; i++) {
    //                 let type = types[i];
    //                 for (let indexProps = 0; indexProps < props.length; indexProps++) {
    //                     let property = props[indexProps];
    //                     if(property?.type?.name === type) arrAux.push(property)
    //                 }
    //             }
    //             return arrAux;
    //         }
    //     }
    //     // props?.filter(
    //     // (p) =>
    //     //     getType(p) === 'Oficina' ||
    //     //     getType(p) === 'Terreno' ||
    //     //     getType(p) === 'Local'
    //     // )
    // };

    // const arrFilteredByOp = (props) => props?.filter((p) =>
    //     getOperations(p).includes('Venta') ||
    //     getOperations(p).includes('Alquiler') ||
    //     getOperations(p).includes('Alquiler Temporario')
    // );

    // const checkProps = (properties,value) => {
    //     let count = 0;
    //     if(value === 'Todos') count++;
    //     switch (value) {
    //         case 'Local':
    //             if(arrFilteredByType(properties, ['Local'])?.length > 0) count++;
    //             break;

    //         case 'Oficina':
    //             if(arrFilteredByType(properties, ['Oficina'])?.length > 0) count++;
    //             break;

    //         case 'viviendas':
    //             if(arrFilteredByType(arrFilteredByOp(properties), ['Casa', 'Departamento', 'PH'])?.length > 0) count++;
    //             break;

    //         case 'Terreno':
    //             if(arrFilteredByType(properties, ['Terreno'])?.length > 0) count++;
    //             break;
    //     }
    //     return count
    // }

    // const checkOperations = (properties,value) => {
    //     let count = 0;
    //     properties.map(property => {
    //         let operation = getOperations(property)
    //         if(operation.find(element => element === value)) {
    //             count++;
    //         }
    //     })
    //     return count
    // }

    const checkOperations = (properties,value) => {
        let count = 0;
        properties.map(property => {
            let operation = getOperations(property)
            if(operation.find(element => element === value)){
                count++;
            }
        })
        return count
    }

    return properties?.data?.objects?.length > 0 ? (
        <section id="featured-props">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h2 class="section-title pb-5">{realEstate.sections.home.properties.title}</h2>
                    </div>
                </div>
            </div>
            {/* { properties?.data?.objects?.length > 0
                ? (<>     */}
                    <div class="container-fluid filter-btn-wrapper">
                        <div className="d-flex flex-wrap justify-content-center">
                            { buttons.map((button,index) => (
                                <button className={'btn button-filter mb-2 mb-lg-0 ' 
                                    + (checkOperations(properties?.data?.objects, button.data) === 0 ? ' d-none ' : '')
                                    + (button.name === "ALQUILER TEMP." ? ' show-btn' : '') // conditional to show TEMPORARIO redirect link
                                    + (filter === button.data ? ' active ' : '')} 
                                    onClick={() => (button.value !== 2) ? setFilter(button.data) : redirectStatic()} key={index}>
                                        {button.name === "ALQUILER TEMP." ?  'TEMPORARIO' : button.name}
                                </button> ))}
                        </div>
                    </div>
                    <div className="props d-flex align-items-center justify-content-center mt-4">
                        <div className="container-fluid">
                            {console.log(properties?.data?.objects)}
                            <OwlCarousel ref={slider}
                                {...options}
                                className="overflow-hidden">
                                {properties?.data?.objects?.filter(property => property.operations.find(operation => operation.operation_type === filter)).map((prop,index) => (
                                    <Card key={index} basic={true} property={prop}/>
                                ))}
                            </OwlCarousel>
                        </div>
                        {/* <div className="arrows">
                            <div className="arrow" onClick={() => slider.current.prev()}> {'<'} </div>
                            <div className="arrow" onClick={() => slider.current.next()}> {'>'} </div>
                        </div> */}
                        <div className={"arrows-owl " + (propArr?.length < 2 && 'd-none')}>
                            <div className="arrow" onClick={() => slider.current.prev()}> <i></i> <i></i></div>
                            <div className="arrow reverse" onClick={() => slider.current.next()}> <i></i> <i></i> </div>
                        </div>
                    </div>
                {/* </>) : null } */}
        </section>
    ):''
}

export default connect(state => ({
    properties: state.properties.featured,
    api_key:state.settings.keys.tokko
}),null)(Properties);