import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import backgroundImg from "../../images/trayectoria.jpg"


const About = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about {
                            title
                            paragraph
                            image
                            buttons {
                              link
                              value
                            }
                            items {
                              paragraph
                              value
                            }
                          }
                        }
                        about {
                            image
                        }
                    }
                }
    }`)

    return realEstate.sections && (
        <section class="about-us py-5" >
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                <div class="row">
                    <div class="col-lg-2 mb-lg-0 mb-4">
                        <p class="title">NUESTRA</p>
                        <p class="title">TRAYECTORIA</p>
                    </div>
                    <div class="col-lg-10 mb-lg-0 mb-4">
                        <p class="content">{realEstate.sections.home.about.paragraph}</p>
                        {/* <h2>SOMOS UN EQUIPO PROFESIONAL, MULTIDISCIPLINARIO,<br class="d-none d-lg-block" /> EMPÁTICO Y RESOLUTIVO EN EL MUNDO DEL REAL ESTATE.</h2> */}
                    </div>
                    <div class="col-12 text-lg-start text-center col-lg-10 mt-lg-5 offset-lg-2">
                        <Link to={realEstate.sections.home.about.buttons[0].link} class="btn btn-tertiary">{realEstate.sections.home.about.buttons[0].value}</Link>
                    </div>
                    {realEstate.sections.home.about.items.length > 1 &&
                        <div class="col-lg-10 offset-lg-2 pt-lg-5 mt-4 mt-lg-5">
                            <div class="row py-lg-5">
                                {realEstate.sections.home.about.items.map((item, index) => (
                                    // index > 1 ?
                                        index % 2 === 0 ?
                                        <div class="col-lg-4 pr-lg-4">
                                            <div class="item">
                                                <div class="row no-gutters align-items-center align-items-lg-start">
                                                    <div class="col-12 text-start">
                                                        <h2 class="hyper d-flex justify-content-center justify-content-lg-start">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span class="counter-value one dos-cifras">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h2>
                                                    </div>
                                                    <div class="col-lg-8 px-3 text-center ">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div class="col-lg-4">
                                            <div class="item" id="counter">
                                                <div class="row no-gutters align-items-center align-items-lg-start">
                                                    <div class="col-12 text-start">
                                                        <h2 class="hyper d-flex justify-content-center justify-content-lg-start">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span class="counter-value one">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h2>
                                                    </div>
                                                    <div class="col-lg-10 px-3 text-center">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))}
                                {/* {realEstate.sections.home.about.items.map((item, index) => (
                                    // index > 1 ?
                                        index % 2 === 0 ?
                                        <div class="col-lg-5 pr-lg-4">
                                            <div class="item">
                                                <div class="row no-gutters">
                                                    <div class="col-6 col-lg-6 text-end">
                                                        <h1 class="hyper d-flex justify-content-end">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span class="counter-value one">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h1>
                                                    </div>
                                                    <div class="col-6 col-lg-6 px-3">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div class="col-lg-7">
                                            <div class="item" id="counter">
                                                <div class="row no-gutters">
                                                    <div class="col-7 text-end">
                                                        <h1 class="hyper d-flex justify-content-end">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span class="counter-value one">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h1>
                                                    </div>
                                                    <div class="col-5 px-3">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))} */}
                            </div>
                        </div>
                }
                </div>

            </div>
        </section>
    )
}

export default About