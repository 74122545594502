import { Link } from 'gatsby';
import React, {useEffect, useRef}  from 'react';
import Searcher from '../search'
import { connect } from 'react-redux';
import { getDevelopmentsAction } from '../../redux/developmentsDucks';
import {getTotalListFilters} from '../../redux/propertiesDucks'
import Loading from '../Loading'
import BannerTop from "../Global/Modules/MediaPush/BannerTop";
import OwlCarousel from 'react-owl-carousel3'
import mp4 from "../../images/banner_baigun.mp4";
import bannerImage from'../../images/bg_baigun.jpg'
import { useLocation } from "@reach/router";

//Helpers
import { getLocation, getLastFeatured } from '../../helpers/helper.developments';
import { getCover } from '../../helpers/helper.rendering';
import { graphql, useStaticQuery } from 'gatsby';
import { getActionByPage, getActionByType } from '../../helpers/helper.actions';
import { informCustomEvent } from '../../helpers/helper.analytics';
import { getCodes } from '../../helpers/helper.actions';

// Mobile conditional styles
import '../../styles/responsive-gallery.scss'

import TopHomegirard from "../../images/TopHomegirard.jpg";

const Home_Main = ({developments,loading,api_key,dispatch,actions,hideActions=false}) =>  {

    const slider = useRef()

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              slogan
              main_image
          }
    }`)

    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction()) // Get all developments
            dispatch(getTotalListFilters()) // Get all developments
        }
    },[api_key])

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + development.id 
        }
    }

    const getSize = () => (screen.width < 992) ? false : true
    
    const goTo = () => {
        if (video_action[0].url_destiny) {
            // window.open(video_action[0].url_destiny, '_blank', 'noopener,noreferrer')
            informCustomEvent("PUSH_" + video_action[0].small_section + "_" + video_action[0].small_type + "_" + video_action[0].name)
            window.open(video_action[0].url_destiny + video_action[0].text_utm, '_blank', 'noopener,noreferrer')
        }
    }

    useEffect(() => {
        if(video_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(video_action[0]?.type,video_action[0]?.section) + "_" + video_action[0]?.name) 
        }
        if(image_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(image_action[0]?.type,image_action[0]?.section) + "_" + image_action[0]?.name) 
        }
        if(slider_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(slider_action[0]?.type,slider_action[0]?.section) + "_" + slider_action[0]?.name) 
        }
    },[])

    const location = useLocation()

    useEffect(() => {
        if((location.pathname.length === 1 && location.pathname === '/')) {
            checkActionsTop()
        }
    },[location])

    const checkActionsTop = () => {
        if (actions && actions.length > 0) {
            const filteredActions = actions.filter(a => a.small_type === "VEN" || a.small_type === "IEN" || a.small_type === "SIE").map(a => [a.section, a.small_type].join())
            // console.log(filteredActions); - // ['Inicio,VEN']
            console.log(filteredActions);
            if (filteredActions.length > 0) {
                const filteredbyPage = filteredActions.filter(p => p.includes("Inicio"))
                return (filteredbyPage.length > 0)
                    ? ((filteredbyPage[0].split(',')[0] === "Inicio") || null) : null
            }
        } else {
            return null
        }
    }

    const slogan = ['BIENVENIDO A GIRARD PROPIEDADES', 'TU LUGAR EN MAR DEL PLATA']

    return !loading ? (
        <>
            { checkActionsTop() ? 
                <div id="home-main" class="banner-main">
                    <BannerTop page="inicio" slogan={slogan}/>
                    <Searcher />
                </div>
             : (!developments?.data?.objects?.length && !getLastFeatured(developments.data.objects) ? (
                    <div id="home-main" class="banner-main">
                        <div class="item d-flex justify-content-center text-center align-items-center h-100">
                            <div class="opacity"></div>
                            <div class="container" style={{zIndex:"1"}}>
                                <div class="row align-items-center text-center justify-content-center">
                                    <div class="col-12 text-content">
                                        <h1>{slogan[0]}</h1> 
                                        <h3>{slogan[1]}</h3> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Searcher />
                    </div>
                ):
                    <div id="home-main" class="banner-main" style={{backgroundImage:"url("+TopHomegirard+")"}}>
                        <div class="item d-flex justify-content-center text-center align-items-center h-100">
                            <div class="opacity"></div>
                            <div class="container" style={{zIndex:"1"}}>
                                <div class="row align-items-center text-center justify-content-center">
                                    <div class="col-12 text-content">
                                        <h1>{slogan[0]}</h1> 
                                        <h3>{slogan[1]}</h3> 
                                        <Searcher />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> )}
        </>
    ):
    <Loading absolute={true} />
}

export default connect(state => ({
    developments: state.developments.developments,
    actions:state.actions.actions,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko
}),null)(Home_Main);