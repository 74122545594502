// import { navigate } from '@reach/router'
import React from 'react'
import { connect } from 'react-redux'
import {getTotalListFilters,setFilterHome, clearPropertiesAction,updateGlobalType, REDUX_UPDATE_FILTERS, getListFilters, getResumeTotalCount,  getListLocations, getAllLocationsAction, getListLocationsAction, REDUX_UPDATE_COORDINATES, REDUX_UPDATE_POLYGON_COORDINATES} from '../redux/propertiesDucks'
import { useRef } from "react";
import { useState, useEffect } from "react";
import Searcher from "./autocomplete";
// import { useNavigate } from '@reach/router'
import { navigate } from "gatsby"
import toast, { Toaster } from 'react-hot-toast';
import { checkCurrentFilters, getTypeCount, getView, getGrandfathers, getFathers, getChildrens, normalizeName} from '../helpers/helper.filters'
import { useLocation } from '@reach/router';
import $ from 'jquery'
import Modal from 'react-bootstrap/Modal';
import { Markup } from 'interweave';

// import { Modal } from 'react-bootstrap';

const Search = ({totalListFilters,loading_Alllocatons,allLocations,previewCount,loading_count,listFilters,searchHome,listLocationFilters,dispatch}) => {

    const [show, setShow] = useState({
        operation:false,
        location:false,
        type:false,
    });

    const handleClose = () => setShow(false);

    // const navigate = useNavigate();
    const refOperation = useRef();
    const refType = useRef();
    const [searcher,setSearcher] = useState("")
    const [searcherType,setSearcherType] = useState("")
    const [locations, setLocation] = useState([]);
    const history = useLocation();

    const [errorInput,setErrorInput] = useState(false)
    const [openOperation,setOpenOperation] = useState(false)
    const [openLocation,setOpenLocation] = useState(false)
    const [openType,setOpenType] = useState(false)

    const [auxFilters,setAuxFilters] = useState()

    // console.log(searchHome)

    // const [router, setRouter] = useState({
    //   location: [],
    //   type: [],
    //   operation: "Tipo de operación",
    // });

    const [routerCache, setRouterCache] = useState();
  
    useEffect(() => {
      if (totalListFilters.data) {
        for (let index = 0; index < totalListFilters.data.objects.locations.length; index++) {
          if(locations.length <= 0){
            setLocation((locations) => [
              ...locations,
              totalListFilters.data.objects.locations[index].location_name,
            ]);
          }
        }
      }
    }, [totalListFilters]);

    const updateTypes = (value) => {
        if(searchHome.type.find(element => element.id === value.id)){
            dispatch(setFilterHome({
                ...searchHome,
                type: searchHome.type.filter(element => element.id !== value.id)
            }));
        }
        else{
            dispatch(setFilterHome({
                ...searchHome,
                type: [...searchHome.type,value]
            }));
        }
    }

    const updateOperation = (value) => {
        dispatch(setFilterHome({
            ...searchHome,
            operation: value.name
        }));
    }

    const updateRoute = (value) => {
        if(value.current.name === 'location'){
            if(totalListFilters.data.objects.locations.find(element => element.location_name.toLowerCase() === value.current.value.toLowerCase())){
                dispatch(setFilterHome({
                  ...searchHome,
                  [value.current.name]: value.current.value,
                }));
            }
        }
        else{
            dispatch(setFilterHome({
                ...searchHome,
                [value.current.name]: value.current.value,
            }));
        }
    };

    const updateLocation = (value) => {

        const {id} = value

        if(searchHome.location.find(element => element.id === id)){
            dispatch(setFilterHome({
                ...searchHome,
                location: searchHome.location.filter(element => element.id !== id)
            }));
        }
        else{
            dispatch(setFilterHome({
                ...searchHome,
                location: [...searchHome.location,value]
            }));
        }
    }
    
    const goRoute = (event) => {
        event.preventDefault();
        if(true){
            dispatch(clearPropertiesAction());
            let routerLink =(
                  searchHome.operation
                  .toLowerCase()
                  .replace(/ /g, "-") +
                  (
                      searchHome.type.length > 0 
                      ? '/' + searchHome.type.map((element,index) => (element.name.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                      :'' 
                  )
                  +
                  (
                    searchHome.location.length > 0 
                    ? '/' +  searchHome.location.map((element,index) => (element.name.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                    :''
                  )
            );
            navigate(routerLink);
        }
        else{
            setErrorInput(true);
            toast.error("No se encontraron resultados para la ubicación ingresada.",4000) 
        }
    };

    useEffect(() => {
        // const location_ = totalListFilters.data.objects.locations.find(element => element.location_name.toLowerCase() === searchHome.location.toLowerCase())
        if(!loading_Alllocatons){
        } 
        if(routerCache !== searchHome){
            // dispatch(getAllLocationsAction())
            dispatch(getListFilters(operationSwitcher()))
            dispatch(getListLocations(operationSwitcher(),searchHome.type !== 'all' ? searchHome.type : ''))
            dispatch(getListLocationsAction(operationSwitcher(),searchHome.type !== 'all' ? searchHome.type : ''))
        }
        setRouterCache(searchHome)
    },[searchHome])

    useEffect(() => {
        dispatch(setFilterHome({...searchHome,type:[],location:[]}))
    },[searchHome.operation])

    useEffect(() => {
        dispatch(setFilterHome({...searchHome,location:[]}))
    },[searchHome.type])

    useEffect(() => {
        dispatch(setFilterHome({
            location: [],
            type: [],
            operation: "Tipo de operación",
        }))
        dispatch(REDUX_UPDATE_FILTERS(
            {
                location: [],
                type: [],
                operation: "",
                environments: "",
                bedrooms: "",
                address:'',
                price:{
                type:'',
                priceMin:'',
                priceMax:''
                },
                surface:{
                type:'',
                surfaceMin:"",
                surfaceMax:"",
                },
                environments_types: [],
                generals: [],
                services: [],
                specials: [],
            }
        ))
        dispatch(REDUX_UPDATE_COORDINATES([]))
        dispatch(REDUX_UPDATE_POLYGON_COORDINATES([]))
    },[history])

    useEffect(() => {
        // dispatch(getResumeTotalCount(searchHome))
        setSearcher("")
        setSearcherType("")
    },[searchHome.location])

    const operationSwitcher = () => {
        switch (searchHome.operation.toLowerCase()) {
            case "venta":
                return 1;
            case 'alquiler':
                return 2;
            case 'tipo de operación':
                return [1,2,3];
            default:
                return 3;
        }
    }

    $(document).on('mouseover',function(e){
        if(e?.target?.className){
            if(typeof e?.target?.className === 'string' 
            && e?.target?.className?.includes("not-closer")){
            }
            else{
                setOpenType(false)
                setOpenLocation(false)
                setOpenOperation(false)
            }
        }
    })

    $(document).on('click',function(e){
        if(e.target.className === 'dropdown-select btn btn-main no-hover true' || e.target.className.includes("not-closer")){
        }
        else{
            setOpenType(false)
            setOpenLocation(false)
            setOpenOperation(false)
        }
    })

    const handleShow = (modal) => {
        switch(modal){
            case "operation" : {
                setShow({...show,operation:!show.operation})
                break
            }
            case "location" : {
                setShow({...show,location:!show.location})
                break
            }
            case "type" : {
                setShow({...show,type:!show.type})
                break
            }
        }
    }

    const handleTogglerSelect = (select) => { //Function to open/close custom-selects
        if(typeof window !== undefined) {
            var width = $(window).width();
            if(width > 919){
                setOpenLocation(false);
                setOpenType(false)
                setOpenOperation(false)
                switch(select){
                    case "operation" : {
                        setOpenOperation(!openOperation)
                        break;
                    }
                    case "location" : {
                        setOpenLocation(!openLocation)
                        break;
                    }
                    case "type" : {
                        setOpenType(!openType);
                        break
                    }
                    default : {
                        break;
                    }
                }
            }
            else{
                handleShow(select)
            }
        }
    }

    const makeElipsisText = (text,length) => {
        if(text.length === 0) return 'Buscar por ubicación';
        let text_adapted = ''
        for(let grandfather of getGrandfathers(allLocations)){
            if(checkAllFatherActives(grandfather)){
                text_adapted += (text_adapted.length > 0 ? ", " : '') + grandfather[0]
            }
            else{
                for(let father of getFathers(grandfather)){
                    if(checkCitiesActive(father)){
                        text_adapted += (text_adapted.length > 0 ? ", " : '') +father[0]
                    }
                    else{
                        for(let children of getChildrens(father)){
                            if(searchHome.location.find(element => element.id === children[1].id)){
                                text_adapted += (text_adapted.length > 0 ? ", " : '' ) + children[0]
                            }
                        }
                    }
                }
            }
        }
        if(text_adapted.length > length){
            return text_adapted.slice(0,length) + "..."
        }
        return text_adapted
    }

    const checkCitiesActive = (parent) => { // // Se fija si todos los hijos de un padre estan activos
        if(Object.keys(parent[1]).length === 2){
            if(searchHome.location.find(location => location.id.toString() === parent[1].id.toString())){
                return true
            }
            return false;
        }
        for(const children of getChildrens(parent)){
            if(searchHome.location.find(element => element.id === children[1].id)){
            }
            else{
                return false
            }
        }
        return true
    }

    const updateLocationByParent = (item) => {
        let newLocations = searchHome.location;
        for(let i = 0; i < getGrandfathers(allLocations).length; i++){ // Me fijo si el abuelo tiene todos los hijos activos, los desactivo y activo solo este padre que estoy clickeando.
            if(getFathers(getGrandfathers(allLocations)[i]).find(element => element[0] === item[0])){
                if(checkAllFatherActives(getGrandfathers(allLocations)[i])){ //Si llegan a estar todos los hijos activos, los desactivo y dejo solo este activo.
                    for(let j = 0; j < getFathers(getGrandfathers(allLocations)[i]).length; j++){ // Recorro todos los "Hermanos" de este padre
                        const brother = getFathers(getGrandfathers(allLocations)[i])[j]
                        for(let c = 0; c < getChildrens(brother).length; c++){ // Recorro los hijos de los hermanos y tambien los deshabilito.
                            let children = getChildrens(brother)[c]
                            newLocations = newLocations.filter(location => location.id !== children[1].id)
                        }
                        newLocations = newLocations.filter(location => location.id !== brother[1].id) //Saco todos los hermanos
                    }
                    if(getChildrens(item).length > 0){ 
                        for(let c = 0; c < getChildrens(item).length; c++){
                            newLocations = [...newLocations, {id:getChildrens(item)[c][1].id,name:getChildrens(item)[c][0]}]
                        }
                        return dispatch(setFilterHome({...searchHome,location:newLocations})) //Si este padre tiene hijos, los activo a ellos.
                    }
                    return dispatch(setFilterHome({...searchHome,location:[...newLocations, {id:item[1].id,name:item[0]}]})) //Si no tiene hijos, solo lo activo a el.
                }
            }
        }
        if(Object.keys(item[1]).length === 2){ // Si no tiene hijos el padre
            return updateLocation({id:item[1].id,name:item[0]})
        }
        if (checkCitiesActive(item)){ // Si todos los hijos del padre están activos, los desactivo.
            for(const children of getChildrens(item)){
                newLocations = newLocations.filter(element => element.id !== children[1].id)
            }
            return dispatch(setFilterHome({...searchHome,location:newLocations.filter(element => element.id !== item[1].id)}))
        }
        else{ // Si hay algun hijo del padre no activo, activo todos los hijos de este padre
            for(const children of getChildrens(item)){
                newLocations = newLocations.filter(element => element.id !== children[1].id)
                newLocations.push({id:children[1].id,name:children[0]})
            }
        }
        return dispatch(setFilterHome({...searchHome,location:[...newLocations, {id:item[1].id,name:item[0]}]}))
    }

    const updateLocationByGrandFather = (item) => {
        const parentFormated = getFathers(item).map( // Formateo todos los hijos de este abuelo.
            element => ({"id":element[1].id,"name":element[0]})
        )
        let newLocations = []
        for(let i = 0; i < searchHome.location.length; i++){ //Recorro los filtros activos para eliminar todos los hijos de este abuelo activo.
            if(parentFormated.find(element => element.id === searchHome.location[i].id)){ //Si encuentro un hijo activo, no lo agrego.
            }
            else{
                newLocations.push({id:searchHome.location[i].id,name:searchHome.location[i].name})//Mantengo los filtros activos que no pertenecer a este abuelo.
            }
        }
        if(!checkAllFatherActives(item)){
            newLocations = [...newLocations, ...parentFormated] //Si no estan todos los hijos de este abuelo activos, los activo.
        }
        else{
            for(let i = 0; i < getFathers(item).length; i++){
                const father = getFathers(item)[i];
                for(let c = 0; c < getChildrens(father).length; c++){
                    const children = getChildrens(father)[c];
                    newLocations = newLocations.filter(element => element.id !== children[1].id) // Elimino los nietos que estan activos.
                }
                //Axel acá tenes eliminar todos los "Nietos" activos que tiene este abuelo. REVISAR PORQUE NO FUNCIONA. ATENCION
            }
        }
        return dispatch(setFilterHome({...searchHome,location:newLocations})) // Si todos los los hijos de este abuelo están activos, los desactivo.
    }

    const checkAllFatherActives = (item) => { // Se fija si todos los hijos de un abuelo estan activos
        for(const father of getFathers(item)){
            if(searchHome.location.find(element => element.id === father[1].id)){
            }
            else{
                return false
            }
        }
        return true
    }

    const unCheckGrandfather = (item) => {
        // console.log(searchHome)
    }

    function toMarkup(text,searcherOperation) {
        if(searcherOperation === ''){
            return text.toLowerCase()
        }
        text = text.toLowerCase().replaceAll(searcherOperation.toLowerCase(), "<b>" + searcherOperation.toLowerCase() +"</b>");
        return text;
    }

    const checkResults = (type) => {
        if (type === 'location'){
            for (let grandfather of getGrandfathers(allLocations)){
                if(grandfather[0].toLowerCase().includes(searcher.toLowerCase())){
                    return false
                }
                for(let father of getFathers(grandfather)){
                    if(father[0].toLowerCase().includes(searcher.toLowerCase())){
                        return false
                    }
                    for(let children of getChildrens(father)){
                        if(children[0].toLowerCase().includes(searcher.toLowerCase())){
                            return false;
                        }
                    }
                }
            }
            return true;
        }
        else{
            for(let type of listFilters.data.objects.property_types ){
                if(type.type.toLowerCase().includes(searcherType.toLowerCase())){
                    return false
                }
            }
            return true
        }
    }

    const makeElipsisTextType = (text,length) => {
        let resume = '';
        text.map(element => (
            resume += element)
            )
        if(resume.length > length){
            return resume.slice(0,length) + "..."
        }
        return resume
    }

    const getHeightOfFloat = () => {
        // var clientHeight = document.getElementById('wrapper-scroll')?.clientHeight - 50;
        // return(clientHeight);
    }

    return totalListFilters ? (
        <>
            <div class="col-12 col-lg-12 not-closer pe-lg-2">
                <div class="row no-gutters d-flex align-items-stretch not-closer">
                    <div class="col-12 col-lg-12 px-2 pr-lg-3 not-closer space-moddifier">
                        <div className={"d-lg-flex content-field content-select not-closer " + (!openLocation && 'close')} >
                            {/* <div onClick={() => toast.error("Debes primero seleccionar un tipo de operación.") } className={"z-index-top " + ((searchHome.operation === "Tipo de operación" ? 'd-block' : 'd-none')) }></div> */}
                            <input type="text" name="search-location" value={searcher} onChange={(e) => setOpenLocation(true) + setSearcher(e.target.value)}placeholder={makeElipsisText(searchHome.location,45)} className="dropdown-select border-round btn btn-main no-hover not-closer text-start br-true true" onClick={(e) => window?.scrollTo(0, getHeightOfFloat()) + handleShow("location") + handleTogglerSelect("location") + e.preventDefault()}/>
                        </div> 
                    </div>
                </div>
            </div>
            <Modal show={show.operation} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" contentClassName="modal-mobile" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Operaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) 
                        ? <div className={'flex-input not-closer '}>
                                <input disabled={loading_count} type="radio"  className="not-closer" name="operation" checked={searchHome.operation === "Venta"} onChange={(e) => updateOperation({id:1,name:"Venta"})} id={"Venta"} value={"Venta"}/>
                                <label className="not-closer" htmlFor="Venta">
                                    <div style={{borderRadius:"100%",overflow:"hidden"}} className=" custom-span"></div>
                                        Venta
                                </label>
                            </div>
                        : '' 
                    }
                        {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 2) 
                        ? <div className={'flex-input not-closer '}>
                                <input disabled={loading_count} type="radio"  className="not-closer" name="operation" checked={searchHome.operation === 'Alquiler'} onChange={(e) => updateOperation({id:2,name:"Alquiler"})} id={"Alquiler"} value={"Alquiler"}/>
                                <label className="not-closer" htmlFor="Alquiler">
                                    <div style={{borderRadius:"100%",overflow:"hidden"}} className=" custom-span"></div>
                                        Alquiler
                                </label>
                            </div>
                        : '' 
                    }
                        {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 3) 
                        ? <div className={'flex-input not-closer '}>
                                <input disabled={loading_count} type="radio"  className="not-closer" name="operation" checked={searchHome.operation === 'Alquiler Temporario'} onChange={(e) => updateOperation({id:1,name:"Alquiler Temporario"})} id={"Alquiler Temporario"} value={"Alquiler Temporario"}/>
                                <label className="not-closer" htmlFor="Alquiler Temporario">
                                    <div style={{borderRadius:"100%",overflow:"hidden"}} className="custom-span"></div>
                                        Alquiler Temporario
                                </label>
                            </div>
                        : '' 
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn w-100 btn-red" onClick={() => handleTogglerSelect("operation")}>{loading_count ? 'Cargando resultados..' : (searchHome.operation === "Tipo de operación" ? ('Aplicar') : ("Aplicar"))}</button>
                </Modal.Footer>
            </Modal>
            {/* Modal Type */}
            <Modal show={show.type} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" contentClassName="modal-mobile" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tipos de propiedades</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="text" className="w-100 search-mobile" name="search-type" value={searcherType} placeholder={"Buscar por tipo"} onChange={(e) => setSearcherType(e.target.value)} />
                    {listFilters.data ? 
                        listFilters.data.objects.property_types.sort((a,b) => {getTypeCount(listFilters.data.objects,a) - getTypeCount(listFilters.data.objects,b)}).map((item,index) => (
                            (item.type.toLowerCase().includes(searcherType.toLowerCase()))  &&
                            <div className={'flex-input not-closer z-index-18 ' + (getTypeCount(listFilters.data.objects,item) === 0 && 'opacity-filter')}>
                                <input disabled={loading_count} type="checkbox"  className="not-closer" name="" onChange={(e) => updateTypes({id:item.id,name:item.type})} id={item.type} value={item.type}/>
                                <label className="not-closer" htmlFor={item.type}>
                                    <div className="square custom-span"></div>
                                    {item.type}
                                </label>
                            </div>
                            )
                            )
                            :''
                        }
                </Modal.Body>
                <Modal.Footer>
                    <div className="control-float position-sticky w-100 d-flex justify-content-between not-closer">
                        <buttton onClick={() => dispatch(setFilterHome({...searchHome, type: []}))} className="btn btn-white not-closer">
                            Limpiar
                        </buttton>
                        <buttton onClick={() => handleTogglerSelect("type") + setOpenLocation(false)} className="btn btn-red border not-closer">
                            Aplicar
                        </buttton>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* Modal Location */}
            <Modal className='modal-pro' show={show.location} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" contentClassName="modal-mobile" centered>
                <Modal.Header closeButton>
                    <Modal.Title>BUSCAR POR UBICACIÓN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <input type="text" className="w-100 search-mobile" name="search-location" value={searcher} placeholder={"Filtrar por ubicación"} onChange={(e) => setSearcher(e.target.value)} />
                {!allLocations?.locations
                    ?   <> </> 
                    :   <>
                        <>
                            {searcher !== '' 
                            ?   <>
                                    {getGrandfathers(allLocations).map((item) => (
                                        <>
                                            {item[0].toLowerCase().includes(searcher.toLowerCase()) && (
                                                <div className="d-flex  w-100 align-items-center not-closer justify-content-start py-1">
                                                    <label className={"option flex-input not-closer"}  >
                                                        <input onChange={() => updateLocationByGrandFather(item)} checked={checkAllFatherActives(item)} id="" type="checkbox" className="radio-custom not-closer " name="location[]" value={item[0]} />
                                                        <span className="custom-input not-closer square"></span> <div className="mark-up"> <Markup content={toMarkup(item[0],searcher)} /></div>
                                                    </label>
                                                </div>)}
                                                {getFathers(item).map((father) => (
                                                    <>
                                                        {(father[0].toLowerCase().includes(searcher.toLowerCase()) &&
                                                            <div className="d-flex  w-100 align-items-center not-closer justify-content-start py-1">
                                                                <label className={"option not-closer flex-input"}  >
                                                                    <input onChange={() => updateLocationByParent(father) } checked={checkCitiesActive(father) && !checkAllFatherActives(item)}  type="checkbox" className="not-closer  radio-custom"  name="location[]" value={father[0]} />
                                                                    <span className="not-closer custom-input square"></span> <div className="mark-up"> <Markup content={toMarkup(father[0],searcher)} /></div>
                                                                </label>
                                                            </div>)}
                                                        {getChildrens(father).map((children) => (
                                                            children[0].toLowerCase().includes(searcher.toLowerCase()) && <div className="d-flex  w-100 align-items-center py-1">
                                                                <label className={"not-closer option flex-input"}  >
                                                                    <input id="" type="checkbox" checked={searchHome.location.find(element => element.id === children[1].id) && !checkAllFatherActives(item)} onChange={() => updateLocation({id:children[1].id,name:children[0]})} className="not-closer radio-custom" name="location[]" value={children[0]} />
                                                                    <span className="not-closer custom-input square"></span> <div className="mark-up"> <Markup content={toMarkup(children[0],searcher)} /></div>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </>
                                                ))}
                                            </>
                                        ))}
                                        {checkResults("location") && <span className="mb-4">No hay resultados sobre <b> "{searcher}"</b>.</span>}
                                </>
                            :   getGrandfathers(allLocations).map((item,i) => ( // List the grandfathers
                                    <div className={""}>
                                        <div className="d-flex  w-100 align-items-center not-closer justify-content-start w-custom py-2">
                                            <div className={"me-2 open-collapse not-closer collapsed d-inline-flex"} data-bs-toggle="collapse" data-bs-target={"#collapse-location-"+normalizeName(item[0])} aria-expanded="false" aria-controls={"collapse-location-"+normalizeName(item[0])}><div className={"symbol not-closer"}></div> </div>
                                            <label className={"mb-0 option flex-input not-closer"}  >
                                                <input onChange={() => updateLocationByGrandFather(item)} checked={checkAllFatherActives(item)} id="" type="checkbox" className="radio-custom not-closer " name="location[]" value={item[0]} />
                                                <span className="custom-input not-closer square"></span> {item[0].replace("\*\*(.*)\*\*", "<b>$1</b>")}
                                            </label>
                                        </div>
                                        <div className="detail-collapse ps-lg-2 ps-2 not-height collapse not-closer " id={"collapse-location-"+normalizeName(item[0])}>
                                            {getFathers(item).map((father) => ( // List the fathers
                                                <div className="d-flex flex-column justify-content-content mb-0 align-items-start not-closer w-custom py-1">
                                                    <div className="d-flex align-items-center w-100 justify-content-center not-closer ">
                                                        <div className={"me-2 not-closer open-collapse collapsed d-inline-flex " + (getChildrens(father).length <= 1 ? ' opacity ' : '')} data-bs-toggle="collapse" data-bs-target={"#collapse-location-"+normalizeName(father[0])} aria-expanded="false" aria-controls={"collapse-location-"+normalizeName(father[0])}><div className={"symbol not-closer"}></div> </div>
                                                        <label className={"option not-closer flex-input"}  >
                                                        {/* +  updateFilters(setFilters,filters,"location", {id:father[1].id,name:father[0]}) */}
                                                            {/* <input onChange={() => updateLocationByParent(father) } checked={checkCitiesActive(father) && !checkAllFatherActives(item)}  type="checkbox" className="not-closer radio-custom"  name="location[]" value={father[0]} /> */}
                                                            <input onChange={() => updateLocationByParent(father) } checked={checkCitiesActive(father)}  type="checkbox" className="not-closer radio-custom"  name="location[]" value={father[0]} />
                                                            <span className="not-closer custom-input square"></span> {father[0]}
                                                        </label>
                                                    </div>
                                                    <div className="detail-collapse ps-lg-5 ms-lg-3 ps-3 children not-height collapse not-closer mb-2" id={"collapse-location-"+normalizeName(father[0])}>
                                                        {getChildrens(father).map((children) => ( // List the childrens
                                                            <label className={"not-closer option flex-input py-1"}  >
                                                                {/* <input id="" type="checkbox" checked={searchHome.location.find(element => element.id === children[1].id) && !checkAllFatherActives(item)} onChange={() => unCheckGrandfather(item) + updateLocation({id:children[1].id,name:children[0]})} className="not-closer radio-custom" name="location[]" value={children[0]} /> */}
                                                                <input id="" type="checkbox" checked={searchHome.location.find(element => element.id === children[1].id)} onChange={() => unCheckGrandfather(item) + updateLocation({id:children[1].id,name:children[0]})} className="not-closer radio-custom" name="location[]" value={children[0]} />
                                                                <span className="not-closer  custom-input square"></span> {children[0]}
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </>
                        </>
                        
                }

                </Modal.Body>
                <Modal.Footer>
                    <div className="control-float float-home position-sticky w-100 d-flex justify-content-between not-closer">
                        <buttton onClick={() => dispatch(setFilterHome({...searchHome, location: []}))} className="btn btn-pink not-closer">
                            Limpiar
                        </buttton>
                        <buttton onClick={() => setShow({...show,location:false})} className="btn btn-red border not-closer">
                            Aplicar
                        </buttton>
                    </div>
                    {/* <button className="btn w-100 btn-red" onClick={() => handleTogglerSelect("location")}>{loading_count ? 'Cargando resultados..' : (searchHome.operation === "Tipo de operación" ? ('Falta operación') : ("Aplicar"))}</button> */}
                    {/* <button className="btn w-100 btn-red" onClick={() => handleTogglerSelect("location")}>{loading_count ? 'Cargando resultados..' : ("Ver " + previewCount + " resultados")}</button> */}
                </Modal.Footer>
            </Modal>
        </>
    ):''
}

export default connect(state => ({
    totalListFilters: state.properties.totalListFilters,
    listFilters: state.properties.listFilters,
    allLocations:state.properties.allLocationFilters,
    loading_Alllocatons:state.properties.loading_Alllocatons,
    listLocationFilters:state.properties.listLocationFilters,
    previewCount: state.properties.previewCount,
    loading_count: state.properties.loading_count,
    filters: state.properties.filters,
    searchHome:state.properties.searchHome
}),null)(Search);
