import { Link } from "gatsby"
import React,{ useEffect } from "react"
import NoveltyCard from "../novelty-card";
import {getNoveltiesAction} from "../../redux/noveltiesDucks"
import {getThree} from '../../helpers/helper.novelty'
import ContentLoader from "react-content-loader"
import { connect } from "react-redux";

const Novelties = ({loading,grid_novelties,dispatch}) => {

    useEffect(() => {
        dispatch(getNoveltiesAction())
    },[])

    return grid_novelties.length > 0 && (
        <section class="novelties py-5" >
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <p class="section-name">NOVEDADES</p>
                    </div>
                    <div class="col-12 col-lg-10">
                        <h3 class="subtitle mt-2">MANTENETE AL TANTO DE LAS ÚLTIMAS TENDENCIAS.</h3>
                    </div>
                    
                    <div class="col-12 col-lg-10 offset-0 offset-lg-2 pt-4">
                        <div class="row py-lg-5">     
                            {
                              loading ?
                                [1,2,3].map((count,index) => (
                                    <div className="mb-4 mb-lg-0 col-12 col-lg-4" key={index}>
                                        <div className='me-3 d-inline'>
                                            <ContentLoader 
                                                speed={2}
                                                width={"100%"}
                                                height={"46vh"}
                                                viewBox="0 0 100% 46vh"
                                                backgroundColor="#f3f3f3ee"
                                                foregroundColor="#ecebebee">
                                                <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
                                            </ContentLoader>
                                        </div>
                                    </div>
                                ))
                                : getThree(grid_novelties).map((novelty,index) => (
                                    <div className="mb-4 mb-lg-0 col-12 col-lg-4" key={index}>
                                        <NoveltyCard isHome={true} novelty={novelty} hidenTags={true} hidenAuthor={true}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div class="col-12 col-lg-10 mt-5 offset-lg-2 text-center text-lg-start">
                        <Link to="novedades" class="btn btn-tertiary">
                            Ver todas las novedades
                            <i className="icon-arrow-right"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    loading: state.novelties.loading,
    grid_novelties: state.novelties.grid_novelties,
  }),null)(Novelties);