// import { navigate } from '@reach/router'
import React from "react"
import { connect } from "react-redux"
import {
  getTotalListFilters,
  clearPropertiesAction,
  setFilterHome,
  REDUX_UPDATE_FILTERS,
  getFiltersByOperation,
  getLocationsLiteByTypeAction,
  getPropertiesByAddress,
} from "../redux/propertiesDucks"
import { useRef } from "react"
import { useState, useEffect } from "react"
import Searcher from "./autocomplete"
import SearchByAddress from "./autocompleteAddress"
import SearcherPro from "./autocompletePro"
// import { useNavigate } from '@reach/router'
import { graphql, navigate, useStaticQuery } from "gatsby"
import toast, { Toaster } from "react-hot-toast"
import $ from "jquery"
import { Modal } from "react-bootstrap"
import {
  checkCurrentFilters,
  getTypeCount,
  getView,
  getGrandfathers,
  getFathers,
  getChildrens,
  normalizeName,
} from "../helpers/helper.filters"
import {redirectStatic} from "../helpers/helper.rendering"

const Search = ({
  totalListFilters,
  listLocationLite,
  allLocationsFilters,
  loading_count,
  searchHome,
  dispatch,
  listFiltersByOperation,
}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        template_version
        proFeatures {
          locationsAdv
        }
      }
    }
  `)
  const { template_version, proFeatures } = realEstate
  const MediaPro = template_version === "PRO" ? true : false
  const { locationsAdv } = proFeatures

  // const navigate = useNavigate();
  const refOperation = useRef()
  const refType = useRef()
  const [locations, setLocation] = useState([])

  const [errorInput, setErrorInput] = useState(false)

  const [router, setRouter] = useState({
    location: "",
    type: "",
    operation: "venta",
    address: "",
  })

  const [show, setShow] = useState({
    operation: false,
    location: false,
    type: false,
  })

  const handleClose = () => setShow(false)

  const [searcherType, setSearcherType] = useState("")

  useEffect(() => {
    if (listLocationLite.data) {
      setLocation([])
      for (
        let index = 0;
        index < listLocationLite.data.objects.locations.length;
        index++
      ) {
        // if(locations.length <= 0){
        setLocation(locations => [
          ...locations,
          listLocationLite.data.objects.locations[index].location_name,
        ])
      }
      // }
    }
  }, [listLocationLite])

  const updateRoute = value => {
    setRouter(router => ({
      ...router,
      [value.current.name]: value.current.value,
    }))
  }

  useEffect(() => {
    switch (router.operation.toLowerCase()) {
      case "venta":
        updateOperation({ id: 1, name: "Venta" })
        break
      case "alquiler":
        updateOperation({ id: 2, name: "Alquiler" })
        break
      case "alquiler temporario":
        updateOperation({ id: 3, name: "Alquiler Temporario" })
        break
      default:
    }
  }, [router.operation])

  const goRoute = event => {
    event.preventDefault()
    if (true) {
      dispatch(clearPropertiesAction())
      let routerLink = searchHome.operation.toLowerCase().replace(/ /g, "-")
      routerLink +=
        searchHome.type.length > 0
          ? "/" +
            searchHome.type.map((element, index) =>
              element
                .toLowerCase()
                .replace(/ /g, "-")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
          : ""
      routerLink +=
        searchHome.location.length > 0
          ? "/" +
            searchHome.location.map((element, index) =>
              element.name
                .toLowerCase()
                .replace(/ /g, "-")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
          : ""
      routerLink += searchHome.address
        ? "/en-calle-" +
          searchHome.address
            .replaceAll(" ", "-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        : ""
      navigate(routerLink)
    }
  }

  const updateOperation = value => {
    dispatch(
      setFilterHome({
        ...searchHome,
        operation: value.name,
        type: [],
        address: "",
      })
    )
  }

  const updateTypes = value => {
    if (searchHome.type.find(element => element === value)) {
      dispatch(
        setFilterHome({
          ...searchHome,
          type: searchHome.type.filter(element => element !== value),
        })
      )
    } else {
      dispatch(
        setFilterHome({
          ...searchHome,
          type: [...searchHome.type, value],
        })
      )
    }
  }

  useEffect(() => {
    dispatch(setFilterHome({ ...searchHome, type: [], location: [] }))
    dispatch(getFiltersByOperation(searchHome.operation))
    dispatch(
      getLocationsLiteByTypeAction(
        searchHome.operation,
        searchHome.type === "all" ? "" : searchHome.type
      )
    )
    $("#type").val("all")
  }, [searchHome.operation])

  useEffect(() => {
    dispatch(setFilterHome({ ...searchHome, location: [] }))
    dispatch(
      getLocationsLiteByTypeAction(
        searchHome.operation,
        searchHome.type === "all" ? "" : searchHome.type
      )
    )
  }, [searchHome.type])

  useEffect(() => {
    dispatch(
      REDUX_UPDATE_FILTERS({
        location: [],
        type: [],
        operation: "",
        environments: "",
        bedrooms: "",
        age: "",
        address: "",
        producer_id: "",
        price: {
          type: "",
          priceMin: "",
          priceMax: "",
          is_off: false,
        },
        surface: {
          type: "",
          surfaceMin: "",
          surfaceMax: "",
        },
        environments_types: [],
        generals: [],
        services: [],
        specials: [],
      })
    )
    dispatch(
      setFilterHome({ operation: "venta", location: [], type: [], address: "" })
    )
  }, [])

  return totalListFilters ? (
    <div
      class={
        "search-home d-flex align-items-center py-3 py-lg-4 " +
        (locationsAdv ? "pro-searcher" : "")
      }
    >
      <div class="container-fluid">
        <form
          onSubmit={goRoute}
          class="row justify-content-center no-gutters align-items-stretch px-xl-5 search-form mb-0"
        >
          <div className={locationsAdv ? "col-lg-6" : "col-lg-5"}>
            <div className="row">
              <div class="col-12 my-2 col-lg-7 px-2 pr-lg-3">
                <div
                  class="btn-group w-100"
                  role="group"
                  aria-label="Basic example"
                >
                  {totalListFilters.data && (
                    <>
                      {totalListFilters.data &&
                      totalListFilters.data.objects.operation_types.find(
                        element => element.operation_type === 1
                      ) ? (
                        <button
                          onClick={() =>
                            updateRoute({
                              current: { value: "venta", name: "operation" },
                            })
                          }
                          type="button"
                          class={
                            "btn btn-primary " +
                            (router?.operation?.toLowerCase() === "venta"
                              ? "active"
                              : "")
                          }
                        >
                          Venta
                        </button>
                      ) : (
                        ""
                      )}
                      {totalListFilters.data &&
                      totalListFilters.data.objects.operation_types.find(
                        element => element.operation_type === 2
                      ) ? (
                        <button
                          onClick={() =>
                            updateRoute({
                              current: { value: "alquiler", name: "operation" },
                            })
                          }
                          type="button"
                          class={
                            "btn btn-primary " +
                            (router?.operation?.toLowerCase() === "alquiler"
                              ? "active"
                              : "")
                          }
                        >
                          Alquiler
                        </button>
                      ) : (
                        ""
                      )}
                      {totalListFilters.data &&
                      totalListFilters.data.objects.operation_types.find(
                        element => element.operation_type === 3
                      ) ? (
                        <button
                          onClick={() =>
                            // updateRoute({
                            //   current: {
                            //     value: "alquiler temporario",
                            //     name: "operation",
                            //   },
                            // })
                            redirectStatic()
                          }
                          type="button"
                          class={
                            "btn btn-primary " +
                            (router?.operation?.toLowerCase() ===
                            "alquiler temporario"
                              ? "active"
                              : "")
                          }
                        >
                          Temporario
                        </button>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
              <div class="col-12 my-2 col-lg-5 px-2 pr-lg-3">
                <div class="content-select">
                  <div
                    className="select"
                    name="type"
                    id="type"
                    ref={refType}
                    onClick={() => setShow({ ...show, type: true })}
                    onChange={() => updateRoute(refType) + updateTypes(refType)}
                  >
                    <div className="value">
                        {searchHome.type.length === 0 ? 'Tipo de propiedad' : searchHome.type.join(", ")}
                    </div>
                  </div>
                  <i></i>
                </div>
              </div>
            </div>
          </div>
          <div class={locationsAdv ? "col-lg-6" : "col-12 col-lg-7"}>
            <div class="row no-gutters d-flex align-items-stretch">
              <div class="col-12 my-2 col-lg-5 px-2 pr-lg-3">
                <div
                  class={"content-select " + (errorInput ? "error-border" : "")}
                >
                  <SearcherPro updateRoute={updateRoute} />
                </div>
              </div>
              <div class="col-12 my-2 col-lg-5     px-2 pr-lg-3">
                <div
                  class={"content-select " + (errorInput ? "error-border" : "")}
                >
                  {locationsAdv ? (
                    <SearchByAddress updateRoute={updateRoute} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="col-12 my-2 px-2 px-lg-0 col-lg-2">
                <button class="btn btn-searcher btn-pink">BUSCAR</button>
              </div>
            </div>
          </div>
          {/* Modal Type */}
          <Modal
            show={show.type}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modal-pro type-modal"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName="modal-mobile"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Tipos de propiedades</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <input
                type="text"
                className="w-100 search-mobile"
                name="search-type"
                value={searcherType}
                placeholder={"Buscar por tipo"}
                onChange={e => setSearcherType(e.target.value)}
              /> */}
              {listFiltersByOperation.data
                ? listFiltersByOperation.data.objects.property_types
                    .sort((a, b) => {
                      getTypeCount(listFiltersByOperation.data.objects, a) -
                        getTypeCount(listFiltersByOperation.data.objects, b)
                    })
                    .map(
                      (item, index) =>
                        item.type
                          .toLowerCase()
                          .includes(searcherType.toLowerCase()) && (
                          <div
                            className={
                              "flex-input not-closer z-index-18 " +
                              (getTypeCount(
                                listFiltersByOperation.data.objects,
                                item
                              ) === 0 && "opacity-filter")
                            }
                          >
                            <input
                              disabled={loading_count}
                              type="checkbox"
                              className="not-closer"
                              name=""
                              checked={searchHome.type.find(
                                element => element === item.type
                              )}
                              onChange={e => updateTypes(item.type)}
                              id={item.type}
                              value={item.type}
                            />
                            <label className="not-closer" htmlFor={item.type}>
                              <div className="square custom-span"></div>
                              {item.type}
                            </label>
                          </div>
                        )
                    )
                : ""}
            </Modal.Body>
            <Modal.Footer>
              <div className="control-float max position-sticky w-100 d-flex justify-content-between not-closer">
                <buttton onClick={() => setShow({...show,type:false}) + dispatch(setFilterHome({...searchHome, type: []}))} className="btn btn-pink not-closer">
                    Limpiar
                </buttton>
                <buttton onClick={() => setShow({...show,type:false})} className="btn btn-red border not-closer">
                    Aplicar
                </buttton>
            </div>
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    </div>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    totalListFilters: state.properties.totalListFilters,
    listLocationLite: state.properties.listLocationLite,
    listFiltersByOperation: state.properties.listFiltersByOperation,
    allLocationsFilters: state.properties.allLocationsFilters,
    loading_count: state.properties.loading_count,
    searchHome: state.properties.searchHome,
  }),
  null
)(Search)
