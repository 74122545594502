import React, {useRef} from 'react'
import Card from '../development-card'
import { graphql, useStaticQuery } from 'gatsby';

import { connect } from 'react-redux'

import {getFeatured, getLastFeatured, getReverseOrder} from '../../helpers/helper.developments'
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop:true,
    smartSpeed:1000,
    stagePadding:10,
    center:true,
    margin:10,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,

        }
    },
}

const developments = ({developments,dispatch}) => {
        const sliderDev = useRef()

    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                sections {
                  home {
                    developments {
                      subtitle
                      sup_title
                      title
                      button {
                        link
                        value
                      }
                    }
                  }
                }
              }
        }`)


    return developments?.data?.objects?.length > 0 && getFeatured(developments?.data?.objects).length > 0 ? (
        <section id="featured-devs">
            <div class="container-fluid pt-3 pt-lg-5">
                <h2 class="section-title py-lg-4 py-2 text-left">{realEstate.sections.home.developments.title}</h2>
            </div>
            <div class="devs mt-lg-4">
                <OwlCarousel 
                    ref={sliderDev}
                    {...options}>
                    {developments.data ?
                    getFeatured((developments.data.objects)).map((development, index) => (
                        <Card development={development} />
                        ))
                    :''}   
                </OwlCarousel> 
                <div className="arrows-owl">
                    <div className="arrow" onClick={() => sliderDev.current.prev()}> <i></i> <i></i></div>
                    <div className="arrow reverse" onClick={() => sliderDev.current.next()}> <i></i> <i></i> </div>
                </div>   
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="text-center suptitle_new">{realEstate.sections.home.developments.sup_title}</h3>
                            <h4 class="text-center subtitle_new">{realEstate.sections.home.developments.subtitle}</h4>
                            <a href={realEstate.sections.home.developments.button.link} className="my-5 d-inline-block btn btn-quarter d-flex m-auto conoce_mas">{realEstate.sections.home.developments.button.value}<i className='icon-arrow-right'></i> </a>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    ) : ''
}

export default connect(state => ({
    developments: state.developments.developments,
}),null)(developments);